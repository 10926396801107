import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const NewFeatures: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage: 'url(../../img/lon-new-features-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>Collection and product pages - new features</h1>
          <p>
            As you know, we are always evolving and improving our platforms to
            propose the best user experience within our website and for this
            reason, we will be adding some new features as well as some slight
            redesigns.
          </p>

          <h3>Collection pages:</h3>
          <p>
            <strong>A slightly new and cleaner design</strong>
          </p>
          <ul>
            <li>We have added more white space,</li>
            <li>new pictograms designs,</li>
            <li>removed the SKU reference at this stage,</li>
            <li>review the CTA designs,</li>
            <li>
              Shortened the height of the main banner to let the users discover
              quickly the first watches on the first line,
            </li>
          </ul>

          <div className="spacer"></div>

          <div className="row">
            <div className="col-md-5 order-2 order-md-1">
              <LightboxWrapper>
                <a href={'../../img/lon-new-features.jpg'}>
                  <img
                    src={'../../img/lon-new-features.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
            <div className="col-md-7 order-1 order-md-2">
              <p>
                <strong>New filter bar: </strong>
              </p>
              <ul>
                <li>
                  More prominent, with the most used filter directly accessible,
                </li>
                <li>
                  The filter bar will be “sticky” and will follow the user once
                  he scrolls down the page.
                </li>
              </ul>

              <p>
                <strong>New variation module: </strong>
              </p>
              <ul>
                <li>
                  Will allow users to directly see all the different
                  configurations of a watch without the need to go onto the
                  product page.
                </li>
              </ul>

              <p>
                <strong>New “lifestyle” pictures: </strong>
              </p>
              <ul>
                <li>
                  To eliminate this “grid” feeling, we will add new PR/Lifestyle
                  pictures within the grid that will render our models more
                  attractive and will, as well, give a deep-dive perspective of
                  the collection universe.
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <LightboxWrapper>
                <a href={'../../img/lon-new-features2.jpg'}>
                  <img
                    src={'../../img/lon-new-features2.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>

            <div className="col-md-6">
              <LightboxWrapper>
                <a href={'../../img/lon-new-features3.jpg'}>
                  <img
                    src={'../../img/lon-new-features3.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
          </div>

          <div className="spacer"></div>
          <hr />
          <div className="spacer"></div>

          <h3>Collection pages:</h3>
          <p>
            Again, in the product pages, the goal is to deliver exactly what the
            client expects at the right time and in the right way. For this, we
            have started adding 2 new major functions to avoid a client having
            to spend too much time to find their perfect watch:
          </p>
          <p>
            <strong>Size:</strong> Switch to a different watch size when it’s
            available,
          </p>
          <p>
            <strong>Variations:</strong> Discover all the configurations of the
            watch without the need to switch the page.
          </p>

          <div className="row">
            <div className="col-md-6">
              <LightboxWrapper>
                <a href={'../../img/lon-new-features4.jpg'}>
                  <img
                    src={'../../img/lon-new-features4.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>

            <div className="col-md-6">
              <LightboxWrapper>
                <a href={'../../img/lon-new-features5.jpg'}>
                  <img
                    src={'../../img/lon-new-features5.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
          </div>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">
                      These new features will come beginning of April
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <h3>More information</h3>
            <p>
              <strong>Pictures:</strong>
            </p>
            <p>
              We are still shooting all our products with 6 to 8 more pictures
              and we presently have more than 320 sku’s live on the website with
              such pictures. This project will continue in 2021.
            </p>
            <p>
              <strong>Product configurator:</strong>
            </p>
            <p>
              We are working on a project “configure your watch” that will allow
              the clients to choose a watch from another entry door, then scroll
              down on the collection pages or by selecting some filters. This
              new way to select a watch will be much more dynamic and fun.
            </p>
          </blockquote>

          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default NewFeatures

export const pageQuery = graphql`
  query NewFeaturesQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
